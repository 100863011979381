import Swiper from 'swiper';
import { Navigation, Pagination, A11y } from 'swiper/modules/index.mjs';
import { debounce } from 'lodash';
import { removeHiddenAssets } from './util';

const SWIPERID = '.js-campaign-slider';

const CampaignTiles = (() => {
    let mySwiper;

    function handleSwiperCampaign() {
        if (!$(SWIPERID).length > 0) {
            return;
        }

        $('.js-wrapper-to-remove').replaceWith($('.js-wrapper-to-remove').html());
        removeHiddenAssets($(SWIPERID), '.swiper-slide');
        const destroyParams = {
            cleanStyles: true,
        };

        if (typeof mySwiper !== 'undefined') {
            mySwiper.destroy(destroyParams);
        }

        mySwiper = undefined;

        const options = {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, A11y],
            spaceBetween: 16,
            slidesPerView: 'auto',
            breakpointsInverse: true,
            pagination: {
                el: '.js-campaign-slider-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.js-campaign-slider-next',
                prevEl: '.js-campaign-slider-prev',
            },
            a11y: {
                prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
                nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
                firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
                lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
            },
            breakpoints: {
                768: {
                    spaceBetween: 20
                }
            }
        };
        if (typeof mySwiper === 'undefined') {
            mySwiper = new Swiper(SWIPERID, options);
        }
    }

    return {
        init() {
            handleSwiperCampaign();
        },
        resize() {
            handleSwiperCampaign();
        },
    };
})();

let windowWidth = $(window).width();

export default (() => {
    $(CampaignTiles.init);

    $(window).on('resize', debounce(function() {
        if ($(window).width() !== windowWidth) {
            windowWidth = $(window).width();
            CampaignTiles.resize();
        }
    }, 300));
})();
